import React, { useEffect, useState } from 'react'
import Firebase from '../Firebase'
import { useNavigate } from 'react-router-dom'
const DashBoardOverAll = () => {
	const[Obj,SetObj]=useState("")
	const navigate=useNavigate()
	const d=new Date()
	const year=d.getFullYear()
	const month=d.getMonth()+1
	const date=d.getDate()
	useEffect(function()
	{
		Firebase.child("Contacts").on("value",function(snap)
	{
		if(snap.val())
		{
		let obj={}
		Object.keys(snap.val()).map((key)=>{
		const datearray=snap.val()[key].Date.split("-")
		if(datearray[0]==year && datearray[1]==month && datearray[2]==date)
		{
			obj[key]= snap.val()[key]
		}
		})
		if(Object.keys(obj).length==0)
		{
			SetObj("")
		}	
		else
		{
			SetObj(obj)
		}
		}
		else
		{
			SetObj("")
		}
	})
	},[])
    return (
        <div>
            <div className="row">
                <div className="col-12 col-xl-12">
                    <div className="card">
                        <div className="card-header pb-0">
                            <h4 className="card-title d-inline-block">Today's Contacts</h4> <a style={{cursor:"pointer"}} onClick={()=>navigate("/CustomersList")}  className="float-end patient-views">Show All</a>
                        </div>
                        <div className="card-block table-dash">
                            <div style={{minHeight:"300px"}} className="table-responsive">
                                <table className="table mb-0 border-0 custom-table comman-table table-hover">
                                    <thead>
                                        <tr className='text-center'>
                                            <th>Sr.No</th>
                                            <th>Name</th>
                                            <th>Phone Number</th>
                                            <th>Email</th>
                                            <th>Message</th>
                                            <th>Insurance</th>
                                            {/* <th>Date</th> */}
											<th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
										{
											Obj?Object.keys(Obj).reverse().map(function(key,index)
										{
												return(
												<tr key={key} className='text-center'>
												<td>{index+1}</td>
												<td>{Obj[key].Name?Obj[key].Name:""}</td>
												<td>{Obj[key].Phone?Obj[key].Phone:""}</td>
												<td>{Obj[key].Email?Obj[key].Email:""}</td>
												<td>{Obj[key].Message?Obj[key].Message:""}</td>
												<td>{Obj[key].Insurance?Obj[key].Insurance:""}</td>
												{/* <td>{Obj[key].Date?Obj[key].Date:""}</td> */}
												<td><button className="custom-badge status-green">{Obj[key].Status?Obj[key].Status:""}</button></td>
												</tr>
												)
										}):<tr className='text-center'>
										<td colSpan={7} className='text-center'>No Record Found</td>
										</tr>
										}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DashBoardOverAll