import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Firebase from '../Firebase';
const AddBlogComponent = () => {
    const[Obj,SetObj]=useState({})
    const[Input,SetInput]=useState(0)
    const[Loader,SetLoader]=useState(false)
    const[ImageArray,SetImageArray]=useState([])
    const d=new Date()
    const date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
    const navigate=useNavigate()
    const CFile1=useRef()
    const Upload1=useRef()
    const CFile2=useRef()
    const Upload2=useRef()
    const CFile3=useRef()
    const Upload3=useRef()
    const row=useRef()
    function set(event)
    {
        SetObj({...Obj,[event.target.name]:event.target.value.trim(),"Date":date})
    }
    function upload(event)
    {
        const file=event.target.files[0]
        if(file)
        {
           const ext= file.type.split("/")
           if(ext[0]=="image")
            {
                if(ext[1]=="png" || ext[1]=="PNG" || ext[1]=="jpeg" || ext[1]=="jpg")
                {
                  const reader= new FileReader()
                  reader.readAsDataURL(file)
                  reader.onload=function()
                  {
                    SetObj({...Obj,["Author_Image"]:reader.result})
                    Upload1.current.style.display="block"
                    CFile1.current.style.display="none"
                  }
                }
                else
                {
                    toast.warn("Only png,jpeg,jpg and PNG are supported")
                    Upload1.current.style.display="none"
                    CFile1.current.style.display="block"
                    CFile1.current.style.textAlign="center"
                    CFile1.current.style.lineHeight="3.2"
                    SetObj({...Obj,["Author_Image"]:""})
                }
            }
            else
            {
                toast.error("Only image is supported")
                Upload1.current.style.display="none"
                CFile1.current.style.display="block"
                CFile1.current.style.textAlign="center"
                CFile1.current.style.lineHeight="3.2"
                SetObj({...Obj,["Author_Image"]:""})
            }
        }
        else
        {
            toast.warn("Image is not uploaded yet.Try again")
            Upload1.current.style.display="none"
            CFile1.current.style.display="block"
            CFile1.current.style.textAlign="center"
            CFile1.current.style.lineHeight="3.2"
            SetObj({...Obj,["Author_Image"]:""})
        }
    }
    function uploads(event)
    {
        const file=event.target.files[0]
        if(file)
        {
           const ext= file.type.split("/")
           if(ext[0]=="image")
            {
                if(ext[1]=="png" || ext[1]=="PNG" || ext[1]=="jpeg" || ext[1]=="jpg")
                {
                  const reader= new FileReader()
                  reader.readAsDataURL(file)
                  reader.onload=function()
                  {
                    SetObj({...Obj,["Heading_Image"]:reader.result})
                    Upload2.current.style.display="block"
                    CFile2.current.style.display="none"
                  }
                }
                else
                {
                    toast.warn("Only png,jpeg,jpg and PNG are supported")
                    Upload2.current.style.display="none"
                    CFile2.current.style.textAlign="center"
                    CFile2.current.style.lineHeight="3.2"
                    CFile2.current.style.display="block"
                    SetObj({...Obj,["Heading_Image"]:""})
                }
            }
            else
            {
                toast.error("Only image is supported")
                Upload2.current.style.display="none"
                CFile2.current.style.display="block"
                CFile2.current.style.textAlign="center"
                CFile2.current.style.lineHeight="3.2"
                SetObj({...Obj,["Heading_Image"]:""})
            }
        }
        else
        {
            toast.warn("Image is not uploaded yet.Try again")
            Upload2.current.style.display="none"
            CFile2.current.style.display="block"
            CFile2.current.style.textAlign="center"
            CFile2.current.style.lineHeight="3.2"
            SetObj({...Obj,["Heading_Image"]:""})
        }
    }
    function number(event)
    { 
        row.current.innerHTML=""
        if(event.target.value<=7)
        {
        SetInput(parseInt(event.target.value) || 0)
        for(let i=0;i<parseInt(event.target.value);i++)
        {
          let myspan=document.createElement("span")
            myspan.className="myspan"

          let box=document.createElement("div")
          box.className="col-12 col-md-6 col-xl-6"
          box.style.float="left"
          box.style.marginRight="8px"
        
          let block=document.createElement("div")
          block.className="form-group local-forms"
        
          let label=document.createElement("label")
          label.innerHTML=`Sub-Heading-${i+1}`
          let span=document.createElement("span")
          span.className="login-danger"
          span.innerHTML="*"
          label.append(span)
          block.append(label)
        
        
          let input=document.createElement("input")
          input.type="text"
          input.className="form-control"
          input.placeholder=`Enter Sub-Heading-${i+1}`
          input.id=`Sub-Heading-${i}`
          block.append(input)
          box.append(block)


          let box1=document.createElement("div")
          box1.className="col-12 col-md-6 col-xl-6"
          box1.style.float="left"
        
          let block1=document.createElement("div")
          block1.className="form-group local-forms"
        
          let label1=document.createElement("label")
          label1.innerHTML=`Sub-Heading-Description-${i+1}`
          let span1=document.createElement("span")
          span1.className="login-danger"
          span1.innerHTML="*"
          label1.append(span1)
          block1.append(label1)
        
        
          let input1=document.createElement("input")
          input1.type="text"
          input1.className="form-control"
          input1.placeholder=`Enter Sub-Heading-Description-${i+1}`
          input1.id=`Sub-Heading-Description-${i}`
          block1.append(input1)
          box1.append(block1)

          myspan.append(box)
          myspan.append(box1)
          row.current.append(myspan)
        }
        }
        else
        {
            toast.error("Only upto 7 in number Sub-headings are allowed")
        }
    }
    function Get()
    {
    var obj={}
    var count=0
    for(var i=0;i<Input;i++)
    {
     var Sub_Heading= document.getElementById(`Sub-Heading-${i}`).value
     var Sub_Heading_Description= document.getElementById(`Sub-Heading-Description-${i}`).value
     if(Sub_Heading!="" && Sub_Heading_Description!="")
     {
      obj[i]={
        "Sub_heading":Sub_Heading,
        "Sub_heading_description":Sub_Heading_Description
      }
     }
     else
     {
      count=count+1
     }
    }
    if(count==0)
    {
      return obj
    }
  }
  function Save(e)
  {
    e.preventDefault()
    let data=Get()
    if(Obj.Title && Obj.Author && Obj.Author_Image && Obj.Category && Obj.Description && Obj.Heading && Obj.Heading_Image && Obj.Tags)
    {
        if(Input>0)
        {
            if(data)
            {
                if(Obj.Status)
                {
                    Firebase.child("Blogs").push({...Obj,"No_sub_heading":Input,"Sub_head_desp":data,"Images":ImageArray},err=>{
                        if(err)
                        {
                            toast.error("Error Occured")
                        }
                        else
                        {
                            toast.success("Blog Uploaded")
                            setTimeout(function()
                            {
                                navigate("/Blog")
                            },1500)
                        }
                    })
                }
                else
                {
                    toast.warn("Please Select the Status")
                }
            }
            else
            {
                toast.warn("Sub-Headings field is empty")
            }
        }
        else
        {
            toast.warn("Number of Sub-Heading not be empty or not be less than 0")
        }
    }
    else
    {
        toast.warn("Field is Empty")
    }
  }
  function uploadmany(event)
  {
    const files=[...event.target.files]
    SetLoader(true)
    let notimagecounter=0
    let notimagetypecounter=0
    let counter=0
    let array=[]
    files.map(function(obj)
    {
       const ext= obj.type.split("/")
       if(ext[0]=="image")
        {
            if(ext[1]=="png" || ext[1]=="PNG" || ext[1]=="jpeg" || ext[1]=="jpg")
            {
                counter=counter+1
                const reader=new FileReader()
                reader.readAsDataURL(obj)
                reader.onload=function()
                {
                    array.push({Path:reader.result,Image_Name:obj.name});
                }
            }
            else
            {
                notimagetypecounter=notimagetypecounter+1
                // alert("Only jpeg,jpg and png images are supported")
            }
        }
        else
        {
            notimagecounter=notimagecounter+1
            // alert("Only Image is allowed")
        }
    })
    if(notimagecounter==0)
    {
        if(notimagetypecounter==0)
        {
            if(counter<=5)
            {
                setTimeout(function()
                {  
                 SetLoader(false)
                 CFile3.current.style.display="none"
                 Upload3.current.style.display="block"
                 SetImageArray([...array]);   
                 toast.success(counter+" Images are uploaded") 
                },1500)
            }
            else
            {
                SetLoader(false)
                SetImageArray([]);  
               toast.error("Only 5 Images are allowed")
                CFile3.current.style.display="block"
                CFile3.current.style.textAlign="center"
                CFile3.current.style.lineHeight="3.2"
                Upload3.current.style.display="none"
            }
        }
        else
        {
            SetLoader(false)
            SetImageArray([]);  
            toast.error("Only jpeg,jpg and png images are supported")
            CFile3.current.style.display="block"
            CFile3.current.style.textAlign="center"
            CFile3.current.style.lineHeight="3.2"
            Upload3.current.style.display="none"
        }
    }
    else
    {
        SetLoader(false)
        SetImageArray([]);  
        toast.error("Only Image is allowed")
        CFile3.current.style.display="block"
        CFile3.current.style.textAlign="center"
        CFile3.current.style.lineHeight="3.2"
        Upload3.current.style.display="none"
    }
  }
    return (
        <div>
            <ToastContainer position='top-center'/>
          {
            Loader?  <div className="preloader">
            <div className="loader"></div>
          </div>:""}
          <div className="row">
          <div className="col-sm-12">
              <div className="card">
                  <div className="card-body">
                      <form>
                          <div className="row">
                              <div className="col-12">
                                  <div className="form-heading">
                                      <h4>Blog Details</h4>
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                  <div className="form-group local-forms">
                                      <label>Blog Title <span className="login-danger">*</span></label>
                                      <input className="form-control" name="Title" onChange={set} type="text" placeholder="Enter Blog Title" />
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                  <div className="form-group local-forms">
                                      <label>Author Name <span className="login-danger">*</span></label>
                                      <input className="form-control" name="Author" onChange={set} type="text" placeholder="Enter Author Name" />
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                  <div className="form-group local-top-form">
                                      <label className="local-top">Author-Image<small>(Size: 150 x 150px)</small> <span className="login-danger">*</span></label>
                                      <div className="settings-btn upload-files-avator">
                                          <input type="file" onChange={upload} accept="image/*" name="Author_Image" className="hide-input" />
                                          <label htmlFor="file" ref={CFile1} className="upload">Choose File</label>
                                          <label style={{display:"none",textAlign:"center",lineHeight:"3.2"}} htmlFor="file" ref={Upload1} className="upload">Image Uploaded</label>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                  <div className="form-group local-forms">
                                      <label>Blog Category <span className="login-danger">*</span></label>
                                      <input type="text" className="form-control" onChange={set} name="Category" placeholder="Enter Blog Category" />
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                  <div className="form-group local-forms">
                                      <label>Tags <small>(separated with a comma)</small> <span className="login-danger">*</span></label>
                                      <input type="text" data-role="tagsinput"onChange={set} name="Tags" className="form-control" placeholder="Enter Blog Tags" />
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                  <div className="form-group local-forms">
                                      <label>Heading <span className="login-danger">*</span></label>
                                      <input className="form-control" onChange={set} name="Heading" type="text" placeholder="Enter Blog Heading" />
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                  <div className="form-group local-forms">
                                      <label>No. of sub-heading <span className="login-danger">*</span></label>
                                      <input className="form-control" onChange={number} name="No_sub_heading" type="tel" maxLength={1} placeholder="Enter Number of sub-heading" />
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                  <div className="form-group local-top-form">
                                      <label className="local-top">Heading-Image <span className="login-danger">*</span></label>
                                      <div className="settings-btn upload-files-avator">
                                          <input type="file" accept="image/*" onChange={uploads} name="Heading_Image" placeholder="Upload your Heading-Image"  className="hide-input" />
                                          <label htmlFor="file" ref={CFile2} className="upload">Choose File</label>
                                          <label style={{display:"none",textAlign:"center",lineHeight:"3.2"}} htmlFor="file" ref={Upload2} className="upload">Image Uploaded</label>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-12 col-md-12 col-xl-12">
                                  <div className="form-group summer-mail">
                                      <label>Description <small /> <span className="login-danger">*</span></label>
                                      <textarea rows={4} cols={5} name="Description" onChange={set} className="form-control" placeholder="Enter your Description here" defaultValue={""} />
                                  </div>
                              </div>
                              <div ref={row}></div>
                              <div className="col-12 col-md-6 col-xl-6">
                                  <div className="form-group local-top-form">
                                      <label className="local-top">Upload your Images <span className="login-danger">(Optional)</span></label>
                                      <div className="settings-btn upload-files-avator">
                                          <input type="file" accept="image/*" name="image" onChange={uploadmany}  placeholder="Upload your Images" className="hide-input" multiple />
                                          <label htmlFor="file" ref={CFile3} className="upload">Choose File</label>
                                          <label style={{display:"none",textAlign:"center",lineHeight:"3.2"}} htmlFor="file" ref={Upload3} className="upload">Images Uploaded</label>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-6">
                                  <div className="form-group select-gender">
                                      <label className="gen-label">Blog Status <span className="login-danger">*</span></label>
                                      <div className="form-check-inline">
                                          <label className="form-check-label">
                                              <input type="radio" onClick={set} name="Status" value={"Active"} className="form-check-input" />Active
                                          </label>
                                      </div>
                                      <div className="form-check-inline">
                                          <label className="form-check-label">
                                              <input type="radio" onClick={set} name="Status" value={"In Active"} className="form-check-input" />In Active
                                          </label>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-12">
                                  <div className="doctor-submit text-end">
                                      <button onClick={Save} className="btn btn-primary submit-form me-2" >Publish Blog</button>
                                      <button type="reset" className="btn btn-primary cancel-form" onClick={()=>navigate("/Blog")}>Cancel</button>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
        </div>
    )
}

export default AddBlogComponent