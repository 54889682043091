import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import '../style.css'
const SidebarHeader = (props) => {
  // const [Toogle, setToogle] = useState(false)
  const [Tooglesecond, setTooglesecond] = useState(true)
  const [ToogleMobile, setToogleMobile] = useState(false)
  const [Tooglemessage, setTooglemessage] = useState(true)

  const first = useRef()
  const second = useRef()
  const third = useRef()
  const fourth = useRef()



  function drop(tooglesubmenu) {

    setTooglesecond(!Tooglesecond)


    if (tooglesubmenu == "first") {
      if (Tooglesecond) {
        first.current.childNodes[0].className = 'subdrop'
        first.current.childNodes[1].style = 'display: block'
      }
      else {
        first.current.childNodes[0].className = ''
        first.current.childNodes[1].style = 'display: none'
      }
    }

    if (tooglesubmenu == "second") {
      // console.log(Tooglesecond)
      if (Tooglesecond) {
        second.current.childNodes[0].className = 'subdrop'
        second.current.childNodes[1].style = 'display: block'
      }
      else {
        second.current.childNodes[0].className = ''
        second.current.childNodes[1].style = 'display: none'
      }
    }

    if (tooglesubmenu == "third") {
      // console.log(Tooglesecond)
      if (Tooglesecond) {
        third.current.childNodes[0].className = 'subdrop'
        third.current.childNodes[1].style = 'display: block'
      }
      else {
        third.current.childNodes[0].className = ''
        third.current.childNodes[1].style = 'display: none'
      }
    }

    if (tooglesubmenu == "fourth") {
      // console.log(Tooglesecond)
      if (Tooglesecond) {
        fourth.current.childNodes[0].className = 'subdrop'
        fourth.current.childNodes[1].style = 'display: block'
      }
      else {
        fourth.current.childNodes[0].className = ''
        fourth.current.childNodes[1].style = 'display: none'
      }
    }
  }

  function openmessage() {
    setTooglesecond(!Tooglesecond)
    var message = document.getElementById('Main-wrapper')

    if (Tooglesecond) {
      message.className = 'main-wrapper open-msg-box'
    }
    else {
      message.className = 'main-wrapper'
    }

  }

  function shownotification(tooglenotification) {

    setTooglemessage(!Tooglemessage)
    if (tooglenotification == "notification") {
      var notificationclassone = document.getElementById('show1')
      var notificationclasstwo = document.getElementById('show2')

      if (Tooglemessage) {
        notificationclassone.className = 'dropdown-toggle nav-link show'
        notificationclasstwo.className = 'dropdown-menu notifications show'
      }
      else {
        notificationclassone.className = 'dropdown-toggle nav-link'
        notificationclasstwo.className = 'dropdown-menu notifications'
      }
    }
     if (tooglenotification == "profile") {
      var notificationclassone = document.getElementById('showprofile1')
      var notificationclasstwo = document.getElementById('showprofile2')

      if (Tooglemessage) {
        notificationclassone.className = 'dropdown-toggle nav-link user-link show'
        notificationclasstwo.className = 'dropdown-menu show'
      }
      else {
        notificationclassone.className = 'dropdown-toggle nav-link user-link'
        notificationclasstwo.className = 'dropdown-menu '
      }
    }
    if (tooglenotification == "Mobileprofile") {
      var notificationclassone = document.getElementById('MobileProfile1')
      var notificationclasstwo = document.getElementById('MobileProfile2')

      if (Tooglemessage) {
        notificationclassone.className = 'dropdown-toggle show'
        notificationclasstwo.className = 'dropdown-menu dropdown-menu-end custom-menu show'
      }
      else {
        notificationclassone.className = 'dropdown-toggle '
        notificationclasstwo.className = 'dropdown-menu dropdown-menu-end custom-menu'
      }
    }


  }

  return (
    <div className={props.tooglebtn ? 'mini-sidebar menu-opened' : ''} >
      <div className={ToogleMobile ? 'main-wrapper slide-nav  ' : 'main-wrapper'} >
        <div className="header">
          <div className="header-left">
            <Link to="/Dashboard" className="logo">
              {
                props.tooglebtn ? <img src="../assets\img\smallLogo\A2Zsmall.png" id="Logo" width={45} height={50} /> :
                  <img src="../assets/img/logo-insurify.png" id="Logo" width={170} height={80} />
              }
            </Link>
          </div>
          <a id="toggle_btn" onClick={props.toggleMargin} style={{cursor:"pointer"}}><img src="../assets/img/icons/bar-icon.svg" /></a>
          <a className="mobile_btn float-start" onClick={() => setToogleMobile(!ToogleMobile)} ><img src="../assets/img/icons/bar-icon.svg" /></a>
          <div className="top-nav-search mob-view">
            <form>
              <input type="text" className="form-control" placeholder="Search here" />
              <a className="btn"><img src="../assets/img/icons/search-normal.svg" /></a>
            </form>
          </div>
          <ul className="nav aa user-menu float-end">
            <li className="nav-item dropdown d-none d-md-block">
              <a onClick={() => shownotification("notification")} id='show1' className="dropdown-toggle nav-link" ><img src="../assets/img/icons/note-icon-02.svg" /><span className="pulse" /> </a>
              <div id='show2' className="dropdown-menu notifications ">
                <div className="topnav-dropdown-header">
                  <span>Notifications</span>
                </div>
                <div className="drop-scroll">
                  {/* <ul className="notification-list">
                          <li className="notification-message">
                              <a href="#">
                                  <div className="media">
											<span className="avatar">
												<img alt="John Doe" src="../assets/img/user.jpg" className="img-fluid">
											</span>
											<div className="media-body">
												<p className="noti-details"><span className="noti-title">John Doe</span> added new task <span className="noti-title">Patient appointment booking</span></p>
												<p className="noti-time"><span className="notification-time">4 mins ago</span></p>
											</div>
                                  </div>
                              </a>
                          </li>
                          <li className="notification-message">
                              <a href="#">
                                  <div className="media">
											<span className="avatar">V</span>
											<div className="media-body">
												<p className="noti-details"><span className="noti-title">Tarah Shropshire</span> changed the task name <span className="noti-title">Appointment booking with payment gateway</span></p>
												<p className="noti-time"><span className="notification-time">6 mins ago</span></p>
											</div>
                                  </div>
                              </a>
                          </li>
                          <li className="notification-message">
                              <a href="#">
                                  <div className="media">
											<span className="avatar">L</span>
											<div className="media-body">
												<p className="noti-details"><span className="noti-title">Misty Tison</span> added <span className="noti-title">Domenic Houston</span> and <span className="noti-title">Claire Mapes</span> to project <span className="noti-title">Doctor available module</span></p>
												<p className="noti-time"><span className="notification-time">8 mins ago</span></p>
											</div>
                                  </div>
                              </a>
                          </li>
                          <li className="notification-message">
                              <a href="#">
                                  <div className="media">
											<span className="avatar">G</span>
											<div className="media-body">
												<p className="noti-details"><span className="noti-title">Rolland Webber</span> completed task <span className="noti-title">Patient and Doctor video conferencing</span></p>
												<p className="noti-time"><span className="notification-time">12 mins ago</span></p>
											</div>
                                  </div>
                              </a>
                          </li>
                          <li className="notification-message">
                              <a href="#">
                                  <div className="media">
											<span className="avatar">V</span>
											<div className="media-body">
												<p className="noti-details"><span className="noti-title">Bernardo Galaviz</span> added new task <span className="noti-title">Private chat module</span></p>
												<p className="noti-time"><span className="notification-time">2 days ago</span></p>
											</div>
                                  </div>
                              </a>
                          </li>
                      </ul> */}
                </div>
                <div className="topnav-dropdown-footer">
                  <a>View all Notifications</a>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown d-none d-md-block">
              <a onClick={openmessage} id="open_msg_box" className="hasnotifications nav-link"><img src="../assets/img/icons/note-icon-01.svg" /><span className="pulse" /> </a>
            </li>
            <li className="nav-item dropdown has-arrow user-profile-list ">
              <a id='showprofile1' onClick={() => shownotification("profile")} className="dropdown-toggle nav-link user-link " data-bs-toggle="dropdown">
                <div className="user-names">
                  <h5>Sudhir Bhayana</h5>
                  <span>Admin</span>
                </div>
                <span className="user-img">
                  <img src="../assets/img/Advisor.jpg" alt="Admin" />
                </span>
              </a>
              <div id='showprofile2' className="dropdown-menu ">
                <Link className="dropdown-item" to={'/MyProfile'} >My Profile</Link>
                <a className="dropdown-item" onClick={() => alert('You are not allowed to Edit. Please Contact to Developer')}>Edit Profile</a>
                <a className="dropdown-item" onClick={() => alert('Website is under progress. Contact to developer')}>Settings</a>
                <Link className="dropdown-item" to={'//a2zinsurance.online'}>Logout</Link>
              </div>
            </li>
            <li className="nav-item ">
              <a onClick={() => alert('Website is under progress. Contact to developer')} className="hasnotifications nav-link"><img src="../assets/img/icons/setting-icon-01.svg" /> </a>
            </li>
          </ul>
          <div className="dropdown mobile-user-menu float-end">
            <a  id='MobileProfile1' onClick={() => shownotification("Mobileprofile")}  className="dropdown-toggle " data-bs-toggle="dropdown show" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical" /></a>
            <div id='MobileProfile2' className="dropdown-menu dropdown-menu-end custom-menu ">
              <Link className="dropdown-item" to={'/MyProfile'} >My Profile</Link>
              <a className="dropdown-item" onClick={() => alert('You are not allowed to Edit. Please Contact to Developer')}>Edit Profile</a>
              <a className="dropdown-item" onClick={() => alert('Website is under progress. Contact to developer')}>Settings</a>
              <Link className="dropdown-item" to="//a2zinsurance.online">Logout</Link>
            </div>
          </div>
        </div>
        <div className="sidebar opened" id="sidebar">
          <div className="sidebar-inner slimscroll" style={{cursor:"pointer"}}>
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li className="menu-title">Main</li>
                <li>
                  <Link className="active" to={'/Dashboard'} ><span className="menu-side"><img src="../assets/img/icons/menu-icon-01.svg" /></span> <span> Dashboard </span> </Link>
                </li>

                <li ref={first} className="submenu">
                  <a onClick={() => drop("first")} ><span className="menu-side"><img src="../assets/img/icons/menu-icon-03.svg" /></span> <span>Customer </span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>
                    <li><Link to={'/CustomersList'}>Customers List</Link></li>
                    <li><Link to={'/AddCustomer'} >Add Customer</Link></li>
                    {/* <li><Link to={'/EditCustomer'} >Edit Customer</Link></li> */}
                  </ul>
                </li>
                <li ref={second} className="submenu">
                  <a onClick={() => drop("second")} ><span className="menu-side"><img src="../assets/img/icons/menu-icon-04.svg" /></span> <span> Approved </span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>
                    <li><Link to={'/Approvelist'} >Approved List</Link></li>
                    {/* <li><Link to={'/CustomerProfile'} >Customer Profile</Link></li> */}
                  </ul>
                </li>
                {/* <li ref={third} className="submenu">
                  <a onClick={() => drop("third")} ><span className="menu-side"><img src="../assets/img/icons/menu-icon-12.svg" /></span> <span> Email</span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>
                    <li><Link to={'/ComposeMail'} >Compose Mail</Link></li>
                    <li><Link to={'/Inbox'} >Inbox</Link></li>
                    <li><Link to={'/MailView'} >Mail View</Link></li>
                  </ul>
                </li> */}
                <li ref={fourth} className="submenu">
                  <a onClick={() => drop("fourth")} ><span className="menu-side"><img src="../assets/img/icons/menu-icon-13.svg" /></span> <span> Blog</span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>
                    <li><Link to={'/Blog'} >Blog</Link></li>
                    {/* <li><Link to={'/BlogView'} >Blog View</Link></li> */}
                    <li><Link to={'/AddBlog'} >Add Blog</Link></li>
                    {/* <li><Link to={'/EditBlog'} >Edit Blog</Link></li> */}
                  </ul>
                </li>
                <li className='submenu'>
                <Link to={"//a2zinsurance.online"}><span className="menu-side"><img src="../assets/img/icons/logout.svg" /></span> <span>Logout</span></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={ToogleMobile ? "sidebar-overlay opened" : ""} onClick={() => setToogleMobile(!ToogleMobile)} data-reff="#sidebar"></div>
    </div>
  )
}
export default SidebarHeader