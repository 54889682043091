import React, { useEffect, useState } from 'react'
import DashBoard from './Pages/DashBoard'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CustomersList from './Pages/CustomersList'
import AddCustomer from './Pages/AddCustomer'
import EditCustomer from './Pages/EditCustomer'
import Approvelist from './Pages/Approvelist'
import CustomerProfile from './Pages/CustomerProfile'
import Inbox from './Pages/Inbox'
import Blog from './Pages/Blog'
import BlogView from './Pages/BlogView'
import AddBlog from './Pages/AddBlog'
import EditBlog from './Pages/EditBlog'
import LockScreen from './Pages/LockScreen'
import FiveHundreadError from './Pages/FiveHundreadError'
import FourZeroFourError from './Pages/FourZeroFourError'
import './assets/plugins/datatables/datatables.min.css'
import './assets/plugins/feather/feather.css'
import './assets/css/style.css'
import './assets/plugins/datatables/datatables.min.css'
import './assets/css/select2.min.css'
import './assets/plugins/fontawesome/css/fontawesome.min.css'
import './assets/plugins/fontawesome/css/all.min.css'
import './assets/css/bootstrap.min.css'
import MyProfile from './Pages/MyProfile'
import Context from './Context'
import Firebase from './Firebase'
const App = () => {
const[State,SetState]=useState("")
const[Condition,SetCondition]=useState(false)
useEffect(function()
{
  SetCondition(true)
  setTimeout(function()
  {
    SetCondition(false)
  },5000)
   Firebase.child("Blogs").on("value",function(snap)
  {
    if(snap.val())
    {
      SetState(snap.val())
    }
    else
    {
      SetState("")
    }
  })
},[])
  return (
    <div>
      {
        Condition?<div className="preloader">
        <div className="loader"></div>
      </div>:
        <Context.Provider value={{"Insert":SetState,"Fetch":State}}>
        <BrowserRouter>
        <Routes>
          <Route path='/' element={<DashBoard/>} />
          <Route path='/Dashboard' element={<DashBoard/>} />
          <Route path='/CustomersList' element={<CustomersList/>} />
          <Route path='/AddCustomer' element={<AddCustomer/>} />
          <Route path='/MyProfile' element={<MyProfile/>} />
          <Route path='/EditCustomer/:key' element={<EditCustomer/>} />
          <Route path='/Approvelist' element={<Approvelist/>} />
          {/* <Route path='/CustomerProfile/:Key' element={<CustomerProfile/>} /> */}
          <Route path='/Inbox' element={<Inbox/>} />
          <Route path='/Blog' element={<Blog/>} />
          <Route path='/BlogView/:key' element={<BlogView/>} />
          <Route path='/AddBlog' element={<AddBlog/>} />
          <Route path='/EditBlog/:key' element={<EditBlog/>} />
          <Route path='/LockScreen' element={<LockScreen/>} />
          <Route path='/500-Error' element={<FiveHundreadError/>} />
          <Route path='/404-Error' element={<FourZeroFourError/>} />
        </Routes>
        </BrowserRouter>
        </Context.Provider>
      }
    </div>
  )
}

export default App