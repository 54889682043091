import React, { useContext, useEffect, useState } from 'react'
import Context from '../Context'
import { useNavigate, useParams } from 'react-router-dom'

const BlogDetailsComponent = () => {
   const{Fetch}= useContext(Context)
   const{key}=useParams()
   const navigate=useNavigate()
   const[Obj,SetObj]=useState("")
   useEffect(function()
    {
        if(key && Fetch)
        {
            SetObj(Fetch[key]);
        }
        else
        {
            SetObj("")
        }
    },[])
    function change(id)
    {
        SetObj(Fetch[id])
    }
    return (
        <div>
            <div className="row">
                <div className="col-md-8">
                    <div className="blog-view">
                        <article className="blog blog-single-post">
                            <h3 className="blog-title">{Obj.Title?Obj.Title:""}</h3>
                            <div className="blog-info clearfix">
                                <div className="post-left date-blks">
                                    <ul>
                                        <li><a href="#"><i className="feather-calendar" /> <span>{Obj.Date?Obj.Date:""}</span></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="blog-image">
                                <a href="#"><img style={{ height: 350 }}  src={Obj.Heading_Image?Obj.Heading_Image:""}  className="img-fluid" /></a>
                            </div>
                            <h4>{Obj.Heading?Obj.Heading:""}</h4>
                            <div className="blog-content">
                                <p>{Obj.Description?Obj.Description:""}</p>
                            </div>
                           <div style={{display:"flex",justifyContent:"center"}}>
                           <div className="blog-image row text-center" style={{display:"flex",justifyContent:"center"}}>
                                {
                                    Obj.Images?Obj.Images.map(function(obj,index)
                                {
                                    return(
                                    <a key={index} style={{height:"200px",width:"350px",margin:"4px"}} href="#"><img height={"100%"} width={"100%"} src={obj.Path?obj.Path:""}  className="img-fluid" /></a>
                                    )
                                }):""
                                }
                            </div>
                           </div>
                            <div>
                                {
                                    Obj.Sub_head_desp?Obj.Sub_head_desp.map(function(obj,index)
                                {
                                    return(
                                        <div key={index}>
                                            <h4>{obj.Sub_heading?obj.Sub_heading:""}</h4>
                                            <div className="blog-content">
                                            <p>{obj.Sub_heading_description?obj.Sub_heading_description:""}</p>
                                            </div>
                                        </div>
                                    )
                                }):""
                                }
                            </div>
                            <div className="list-add-blogs">
                                <ul className="nav">
                                    {
                                        Obj.Tags?Obj.Tags.split(",").map((tag)=><li key={tag}>{tag}</li>):""
                                    }
                                    {/* <li># Ophthalmology</li>
										<li># Beauty</li>
										<li># Prevention</li> */}
                                </ul>
                            </div>
                        </article>
                        <div className="widget author-widget ">
                            <div className="authr-blog-group text-center">
                                <div className="authr-blg-img mb-2">
                                     <img className="avatar" src={Obj.Author_Image?Obj.Author_Image:""}  /> 
                                </div>
                                <h2>{Obj.Author?Obj.Author:""}</h2>
                                <span>Author</span>
                                <p>Author plays an important role by taking responsibility and accountability for published work. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <aside className="col-md-4">
                    <div className="widget post-widget">
                        <div className="relat-head">
                            <h5>Most Reads</h5>
                            <a onClick={()=>navigate("/Blog")} style={{cursor:"pointer"}}>Show All</a>
                        </div>
                        <ul className="latest-posts">
                            {
                                Fetch?Object.keys(Fetch).reverse().map(function(id)
                                {
                                    if(id!=key && Fetch[id].Status=="Active")
                                    {
                                        return(
                                            <li key={id}>
                                            <div className="post-thumb">
                                                <a onClick={()=>change(id)}>
                                                    <img style={{height:"65px"}} className="img-fluid" src={Fetch[id].Heading_Image?Fetch[id].Heading_Image:"../assets/img/blog/blog-12.jpg"} />
                                                </a>
                                            </div>
                                            <div className="post-info">
                                                <div className="date-posts">
                                                    <h5>{Fetch[id].Status?Fetch[id].Status:""}</h5>
                                                    <span className="ms-2">{Fetch[id].Date?Fetch[id].Date:""}</span>
                                                </div>
                                                <h4>
                                                    <a onClick={()=>change(id)}>{Fetch[id].Title?Fetch[id].Title:""}</a>
                                                </h4>
                                                <p onClick={()=>change(id)}> Read more<i className="fa fa-long-arrow-right ms-2" /></p>
                                            </div>
                                        </li>
                                        )
                                    }
                                }):""
                            }
                            {/* <li>
                                <div className="post-thumb">
                                    <a href="blog-details.html">
                                        <img className="img-fluid" src="../assets/img/blog/blog-11.jpg"  />
                                    </a>
                                </div>
                                <div className="post-info">
                                    <div className="date-posts">
                                        <h5>Health</h5>
                                        <span className="ms-2">05 Sep 2022</span>
                                    </div>
                                    <h4>
                                        <a href="blog-details.html">Hydration or Moisturization – What to do this Winter?</a>
                                    </h4>
                                    <p> Read more in 10 Minutes<i className="fa fa-long-arrow-right ms-2" /></p>
                                </div>
                            </li>
                            <li>
                                <div className="post-thumb">
                                    <a href="blog-details.html">
                                        <img className="img-fluid" src="../assets/img/blog/blog-12.jpg" />
                                    </a>
                                </div>
                                <div className="post-info">
                                    <div className="date-posts">
                                        <h5>Ophthalmology</h5>
                                        <span className="ms-2">05 Sep 2022</span>
                                    </div>
                                    <h4>
                                        <a href="blog-details.html">Hair Loss – Causes, Treatment and Preventions</a>
                                    </h4>
                                    <p> Read more in 5 Minutes<i className="fa fa-long-arrow-right ms-2" /></p>
                                </div>
                            </li>
                            <li>
                                <div className="post-thumb">
                                    <a href="blog-details.html">
                                        <img className="img-fluid" src="../assets/img/blog/blog-13.jpg"  />
                                    </a>
                                </div>
                                <div className="post-info">
                                    <div className="date-posts">
                                        <h5>Safety</h5>
                                        <span className="ms-2">05 Sep 2022</span>
                                    </div>
                                    <h4>
                                        <a href="blog-details.html">Simple Changes That Lowered My Mom's Blood Pressure</a>
                                    </h4>
                                    <p> Read more in 4 Minutes<i className="fa fa-long-arrow-right ms-2" /></p>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </aside>
            </div>

        </div>
    )
}

export default BlogDetailsComponent