import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/database"
const firebaseConfig = {
  apiKey: "AIzaSyDt_Fj3J-kixtXeTj3cKNLulyuDDPDKWys",
  authDomain: "insurance-f28df.firebaseapp.com",
  databaseURL: "https://insurance-f28df-default-rtdb.firebaseio.com",
  projectId: "insurance-f28df",
  storageBucket: "insurance-f28df.appspot.com",
  messagingSenderId: "838660135539",
  appId: "1:838660135539:web:8ac502243554407e492806"
};

const fire=firebase.initializeApp(firebaseConfig);
export default fire.database().ref()