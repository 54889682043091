import React, { useState } from 'react'
import PageHeader from '../Components/PageHeader'
import SidebarHeader from '../Components/SidebarHeader'
import InboxComponent from '../Components/InboxComponent'
import Messages from '../Components/Messages'

const Inbox = () => {
  const [isToggled, setIsToggled] = useState(false);
  
  const toggleMargin = () => {
    setIsToggled(!isToggled);
    
  };
  return (
    <div id='Main-wrapper' className='main-wrapper' >
     <SidebarHeader toggleMargin={toggleMargin} tooglebtn={isToggled} />

        <div style={{ marginLeft: isToggled ? '60px' : '' }}  className="page-wrapper">
<div className="content">
<PageHeader Domain={"Email"} SubDomain={"Inbox"}  />
<InboxComponent/>
<Messages/>
</div>
</div>

  </div>
  )
}

export default Inbox