import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Firebase from '../Firebase'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const EditCustomerComponent = () => {
    const[Obj,SetObj]=useState("")
    const {key}=useParams()
    useEffect(function()
    {
        Firebase.child(`Contacts/${key}`).on("value",function(snap)
    {
        if(snap.val())
        {
            SetObj(snap.val())
        }
        else
        {
            SetObj("")
        }
    })
    },[])
    const navigate=useNavigate()
    function set(event)
    {
        SetObj({...Obj,[event.target.name]:event.target.value})
    }
    function Save(e)
    {
        e.preventDefault()
        if(Obj.Name && Obj.Email && Obj.Phone && Obj.Insurance!="None")
        {
            if(Obj.Status)
            {
                Firebase.child(`Contacts/${key}`).update(Obj,err=>{
                    if(err)
                    {
                        toast.error("Error Occured")
                    }
                    else
                    {
                        toast.success("Customer Updated")        
                    }
                })
                setTimeout(() => {
                     navigate("/CustomersList")
                }, 2000);
            }
            else
            {
                toast.warning("Select the Status") 
            }
        }
        else
        {
            toast.warning("Field is Empty")
        }
    }
    return (
        <div>
            <ToastContainer position='top-center'/>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-heading">
                                            <h4>Change Details</h4>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                            <label> Name <span className="login-danger">*</span></label>
                                            <input className="form-control" name="Name" onChange={set} type="text" value={Obj.Name?Obj.Name:""} placeholder="Enter the Name" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                            <label>Email <span className="login-danger">*</span></label>
                                            <input className="form-control" name="Email" type="email" onChange={set} value={Obj.Email?Obj.Email:""} placeholder="Enter the Email Address" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                            <label>Phone Number <span className="login-danger">*</span></label>
                                            <input className="form-control" name="Phone" onChange={set} type="tel" value={Obj.Phone?Obj.Phone:""} placeholder="Enter the Phone Number" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                            <label>Date of Contact <span className="login-danger">*</span></label>
                                            <input className="form-control" onChange={set} name="Date" type="date" value={Obj.Date?Obj.Date:""} placeholder="Enter or chose Date" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                            <label>Address <span className="login-danger">*</span></label>
                                            <input className="form-control" onChange={set} value={Obj.Address?Obj.Address:""} name="Address" type="text" placeholder="Enter the Address" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                            <label>Insurance<span className="login-danger">*</span></label>
                                            <select className="form-control select" onChange={set} value={Obj.Insurance?Obj.Insurance:""} name="Insurance">
                                                <option value="None">Select Insurance</option>
                                                <option value="Life Insurance">Life Insurance</option>
                                                <option value="Health Insurance">Health Insurance</option>
                                                <option value="Motor Insurance">Motor Insurance</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group local-forms">
                                            <label>Message<span className="login-danger">*</span></label>
                                            <textarea className="form-control" value={Obj.Message} onChange={set} name="Message" rows={3} cols={30} placeholder="Enter the Message" defaultValue={""} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group select-gender">
                                            <label className="gen-label">Status<span className="login-danger">*</span></label>
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input type="radio" onClick={set} name="Status" value={"Approved"} className="form-check-input"  checked={Obj.Status=="Approved"?true:false} readOnly={true}/>Approve
                                                </label>
                                            </div>
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input type="radio" onClick={set} name="Status" value={"Not Approved"} className="form-check-input" checked={Obj.Status=="Not Approved"?true:false} readOnly={true} />Not Approved
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="doctor-submit text-end">
                                            <button className="btn btn-primary submit-form me-2" onClick={Save} >Submit</button>
                                            <button type="button" className="btn btn-primary cancel-form" onClick={()=>navigate("/CustomersList")} >Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditCustomerComponent