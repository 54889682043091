import React from 'react'

const FourZeroFourError = () => {
  return (
    <div className='error-pages' >
     <div className="main-wrapper error-wrapper">
  <div className="error-box">
    <img className="img-fluid" src="../assets/img/error-01.png" alt="Logo" />
    <h3><img className="img-fluid mb-0" src="../assets/img/icons/danger.svg" alt="Logo" />  Service Unavailable</h3>
    <p>You may have mistyped the address or the page may have moved.</p>
    <a href="index.html" className="btn btn-primary go-home">Back to Home</a>
  </div>
</div>

    </div>
  )
}

export default FourZeroFourError