import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS,BarElement, CategoryScale, LinearScale, Title, ArcElement, Tooltip, Legend } from 'chart.js';
import Firebase from '../Firebase';
import DashBoardOverAll from './DashBoardOverAll';

ChartJS.register(ArcElement, Tooltip, Legend,BarElement, CategoryScale, LinearScale, Title);
const DashBoardData = () => {
  const[Obj,SetObj]=useState("")
  const[Approve,SetApprove]=useState(0)
  useEffect(function()
  {
    Firebase.child("Contacts").on("value",function(snap)
  {
    if(snap.val())
    {
      SetObj(snap.val())
    }
    else
    {
      SetObj("")
    }
  })
  },[])
  useEffect(function()
  {
      const result= Object.keys(Obj).filter((key)=>Obj[key].Status=="Approved").length || 0
      SetApprove(result)
  },[Obj])
  const data = {
    labels: ['Not Approved', 'Approved'],
    datasets: [
      {
        label: 'Number of Customers',
        data: [Object.keys(Obj).length-Approve ||1 ,Approve ||0],
        backgroundColor: [
          'orange',
          'rgba(24,205,98,255)',
        ],
      },
    ],
  };
  return (
<div>
<div className="row">
  <div className="col-12 col-md-12 col-lg-6 col-xl-9">
    <DashBoardOverAll/>
  </div>
  <div className="col-12 col-md-12 col-lg-6 col-xl-3 d-flex">
    <div className="card">
      <div className="card-body">
        <div className="chart-title">
          <h4>Overall</h4>
        </div>	
        <div id="donut-chart-dash" className="chart-user-icon">
        <Doughnut data={data} />
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}
export default DashBoardData