import React from 'react'

const LockScreen = () => {
    return (
        <div className='main-wrapper login-body' >
            <div className="container-fluid px-0">
                <div className="row">
                    {/* Login logo */}
                    <div className="col-lg-6 login-wrap">
                        <div className="login-sec">
                            <div className="log-img">
                                <img className="img-fluid" style={{ borderRadius: '0% 8%' }} src="../assets/img/Poster.PNG" alt="Logo" />
                            </div>
                        </div>
                    </div>
                    {/* /Login logo */}
                    {/* Login Content */}
                    <div className="col-lg-6 login-wrap-bg">
                        <div className="login-wrapper">
                            <div className="loginbox">
                                <div className="login-right">
                                    <div className="login-right-wrap">
                                        <div className="account-logo">
                                            <a style={{ display: 'flex', justifyContent: 'center' }} href="#"><img height="150vh" width="65%" src="../wp-content/uploads/sites/360/2023/03/logo-insurify.png" alt /></a>
                                        </div>
                                        <div className="user-lock-screen">
                                            <h2>Hi, Sudhir Bhayana</h2>
                                            <p>Enter your password to unlock the account</p>
                                        </div>
                                        {/* Form */}
                                        <form action="#">
                                            <div className="form-group">
                                                <label>Password <span className="login-danger">*</span></label>
                                                <input className="form-control pass-input" type="password" id="Password" />
                                                <span className="profile-views feather-eye-off toggle-password" />
                                            </div>
                                            <div className="form-group login-btn">
                                                <button className="btn btn-primary btn-block" type="button" onclick="Unlock()">Unlock</button>
                                            </div>
                                        </form>
                                        {/* /Form */}
                                        {/* <div class="next-sign">
											<p class="account-subtitle">Sign in as a different user?   <a href="register.html">Login</a></p>
											
											 Social Login */}
                                        {/* <div class="social-login">
												<a href="javascript:;" ><img src="../assets/img/icons/login-icon-01.svg" alt=""></a>
												<a href="javascript:;" ><img src="../assets/img/icons/login-icon-02.svg" alt=""></a>
												<a href="javascript:;" ><img src="../assets/img/icons/login-icon-03.svg" alt=""></a>
											</div> */}
                                        {/* /Social Login
											
										</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Login Content */}
                </div>
            </div>

        </div>
    )
}

export default LockScreen