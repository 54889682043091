import React, { useState } from 'react'
import EditBlogComponent from '../Components/EditBlogComponent'
import SidebarHeader from '../Components/SidebarHeader'
import PageHeader from '../Components/PageHeader'
import Messages from '../Components/Messages'

const EditBlog = () => {
  const [isToggled, setIsToggled] = useState(false);
  
  const toggleMargin = () => {
    setIsToggled(!isToggled);
    
  };
  return (
    <div id='Main-wrapper' className='main-wrapper' >
     <SidebarHeader toggleMargin={toggleMargin} tooglebtn={isToggled} />

        <div style={{ marginLeft: isToggled ? '60px' : '' }}  className="page-wrapper">
<div className="content">
<PageHeader Domain={"Blog"} SubDomain={"Edit Blog"}  />
<EditBlogComponent/>
<Messages/>
</div>
</div>

  </div>
  )
}

export default EditBlog