import React, { useState } from 'react'
import SidebarHeader from '../Components/SidebarHeader'
import CustomerListTable from '../Components/CustomerListTable'
import PageHeader from '../Components/PageHeader'
import Messages from '../Components/Messages'

const CustomersList = () => {
  const [isToggled, setIsToggled] = useState(false);
  
  const toggleMargin = () => {
    setIsToggled(!isToggled);
  };
  return (
<div id='Main-wrapper' className='main-wrapper' >
            <SidebarHeader toggleMargin={toggleMargin} tooglebtn={isToggled} />
      <div style={{ marginLeft: isToggled ? '60px' : '' }} className="page-wrapper">
  <div className="content">
  <PageHeader Domain={"Customers"} SubDomain={"Customers List"}  />
    <CustomerListTable/>
    <Messages/>
  </div>
</div>

    </div>
  )
}

export default CustomersList