import React, { useContext, useEffect, useState } from 'react'
import Firebase from '../Firebase'
import CountUp from 'react-countup'
import Context from '../Context'

const DashboardSale = () => {
  const[Obj,SetObj]=useState("")
  const[Approved,SetApproved]=useState(0)
  const[Active,SetActive]=useState(0)
 const{Fetch}= useContext(Context)
  useEffect(function()
  {
    Firebase.child("Contacts").on("value",function(snap)
  {
    if(snap.val())
    {
      SetObj(snap.val())
    }
    else
    {
      SetObj("")
    }
  })
  },[])
  useEffect(function()
{
  const result= Object.keys(Obj).filter((key)=>Obj[key].Status=="Approved") || 0
  SetApproved(result.length)
},[Obj])
useEffect(function()
{
  const result= Object.keys(Fetch).filter((key)=>Fetch[key].Status=="Active") || 0
  SetActive(result.length)
},[Fetch])
  return (
    <div>
<div className="row">
  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
    <div className="dash-widget">
      <div className="dash-boxs comman-flex-center">
        <img src="../assets/img/icons/calendar.svg"  />
      </div>
      <div className="dash-content dash-count">
        <h4>All Time</h4>
        <h2 id="counter-up1"><CountUp end={Obj?Object.keys(Obj).length:0}/><span className="counter-up" /></h2>
        {/* <p><span className="passive-view"><i className="feather-arrow-up-right me-1" />40%</span> vs last month</p> */}
      </div>
    </div>
  </div>
  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
    <div className="dash-widget">
      <div className="dash-boxs comman-flex-center">
        <img src="../assets/img/icons/profile-add.svg"  />
      </div>
      <div className="dash-content dash-count">
        <h4>Approved</h4>
        <h2 id="counter-up2"><CountUp end={Approved}/><span className="counter-up" /></h2>
        {/* <p><span className="passive-view"><i className="feather-arrow-up-right me-1" />20%</span> vs last month</p> */}
      </div>
    </div>
  </div>
  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
    <div className="dash-widget">
      <div className="dash-boxs comman-flex-center">
        <img src="../assets/img/icons/scissor.svg"  />
      </div>
      <div className="dash-content dash-count">
        <h4>Total Blogs</h4>
        <h2 id="counter-up3"><CountUp end={Fetch?Object.keys(Fetch).length:0}/><span className="counter-up" /></h2>
        {/* <p><span className="negative-view"><i className="feather-arrow-down-right me-1" />15%</span> vs last month</p> */}
      </div>
    </div>
  </div>
  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
    <div className="dash-widget">
      <div className="dash-boxs comman-flex-center">
        <img src="../assets/img/icons/empty-wallet.svg"  />
      </div>
      <div className="dash-content dash-count">
        <h4>Active Blogs</h4>
        <h2 id="counter-up4"><CountUp end={Active}/><span className="counter-up" /></h2>
        {/* <p><span className="passive-view"><i className="feather-arrow-up-right me-1" />30%</span> vs last month</p> */}
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default DashboardSale