import React, { useRef, useState } from 'react'
import SidebarHeader from '../Components/SidebarHeader'
import GoodMorningBlk from '../Components/GoodMorningBlk'
import PageHeader from '../Components/PageHeader'
import DashboardSale from '../Components/DashboardSale'
import DashBoardData from '../Components/DashBoardData'
import Messages from '../Components/Messages'

const DashBoard = () => {
  const [isToggled, setIsToggled] = useState(false);
  const toggleMargin = () => {
    setIsToggled(!isToggled);
  };
  return (
    <div id='Main-wrapper' className='main-wrapper' >
        <SidebarHeader toggleMargin={toggleMargin} tooglebtn={isToggled} />
        <div style={{ marginLeft: isToggled ? '60px' : '' }}  className="page-wrapper">
            <div className="content">
                <PageHeader Domain={"Dashboard"} SubDomain={""}  />
                <GoodMorningBlk/>
                <DashboardSale/>
                <DashBoardData/>
                <Messages/>
            </div>
        </div>
    </div>
  )
}
export default DashBoard