import React from 'react'

const InboxComponent = () => {
  return (
    <div>
      <div className="row">
  {/* <div class="col-xl-4 d-flex">
						<div class="card chat-box">
							<div class="chat-widgets">
								<div class="chat-user-group d-flex align-items-center">
									<div class="img-users call-user">
										<img src="../assets/img/profiles/avatar-01.jpg" alt="img">
									</div>
									<div class="chat-users user-main">
										<div class="user-titles user-head-compse">
											<h5> William Stephin	</h5>
											<div class="chat-user-time">
												<p>10:22 AM</p>
											</div>
										</div>
										<div class="drop-item chat-menu user-dot-list">
											<a href="#" data-bs-toggle="dropdown" aria-expanded="false" class="">
												<i class="feather-more-vertical"></i>
											</a>
											<div class="dropdown-menu" style="">
												<a class="dropdown-item" href="javascript:;"><i class="feather-user me-2 text-primary"></i> Profile</a>
												<a class="dropdown-item" href="javascript:;"><i class="feather-plus-circle me-2 text-success"></i> Archive</a>
												<a class="dropdown-item" href="javascript:;"><i class="feather-trash-2 me-2 text-danger"></i> Delete</a>
												<a class="dropdown-item " href="javascript:;"><i class="feather-slash me-2 text-secondary"></i> Block</a>
											</div>
										</div>
									</div>
								</div>
								<div class="compose-mail">
									<a href="compose.html" class="btn btn-primary"><img src="../assets/img/icons/edit-2.svg" class="me-2" alt="img">Compose Mail</a>
								</div>
								<div class="email-menu-blk">
									<ul >
										<li class="active"><a href="javascript:;"><img src="../assets/img/icons/inbox.svg" class="me-2" alt="img">Inbox<span class="comman-flex">50</span></a></li>
										<li><a href="javascript:;"><img src="../assets/img/icons/sent.svg" class="me-2" alt="img">Sent <span class="comman-flex">120</span></a></li>
										<li><a href="javascript:;"><img src="../assets/img/icons/draft.svg" class="me-2" alt="img">Draft <span class="comman-flex">20</span></a></li>
										<li><a href="javascript:;"><img src="../assets/img/icons/star.svg" class="me-2" alt="img">Starred <span class="comman-flex">05</span></a></li>
										<li><a href="javascript:;"><img src="../assets/img/icons/trash.svg" class="me-2" alt="img">Trash <span class="comman-flex">12</span></a></li>
									</ul>
								</div>
								<div class="label-blk comman-space-flex">
									<h4>Labels</h4>
									<ul class="nav label-add-list">
										<li><a href="javascript:;" class="add-list-btn me-2"><i class="feather-plus "></i></a></li>
										<li>
											<a href="javascript:;" data-bs-toggle="dropdown" aria-expanded="false" class="add-list-btn">
												<i class="feather-more-vertical"></i>
											</a>
											<div class="dropdown-menu" style="">
												<a class="dropdown-item" href="javascript:;"><i class="feather-user me-2 text-primary"></i> Profile</a>
												<a class="dropdown-item" href="javascript:;"><i class="feather-plus-circle me-2 text-success"></i> Archive</a>
												<a class="dropdown-item" href="javascript:;"><i class="feather-trash-2 me-2 text-danger"></i> Delete</a>
												<a class="dropdown-item " href="javascript:;"><i class="feather-slash me-2 text-secondary"></i> Block</a>
											</div>
										</li>
									</ul>
								</div>
								<div class="email-menu-blk">
									<ul >
										<li ><a href="javascript:;"><img src="../assets/img/icons/tag-icon-01.svg" class="me-2" alt="img">Work<span class="comman-flex">50</span></a></li>
										<li><a href="javascript:;"><img src="../assets/img/icons/tag-icon-02.svg" class="me-2" alt="img">Personal <span class="comman-flex">120</span></a></li>
										<li><a href="javascript:;"><img src="../assets/img/icons/tag-icon-03.svg" class="me-2" alt="img">Read Later <span class="comman-flex">20</span></a></li>
									</ul>
								</div>
								<div class="label-blk comman-space-flex">
									<h4>Folders</h4>
									<ul class="nav label-add-list ">
										<li><a href="javascript:;" class="add-list-btn me-2"><i class="feather-plus "></i></a></li>
										<li>
											<a href="javascript:;" data-bs-toggle="dropdown" aria-expanded="false" class="add-list-btn">
												<i class="feather-more-vertical"></i>
											</a>
											<div class="dropdown-menu" style="">
												<a class="dropdown-item" href="javascript:;"><i class="feather-user me-2 text-primary"></i> Profile</a>
												<a class="dropdown-item" href="javascript:;"><i class="feather-plus-circle me-2 text-success"></i> Archive</a>
												<a class="dropdown-item" href="javascript:;"><i class="feather-trash-2 me-2 text-danger"></i> Delete</a>
												<a class="dropdown-item " href="javascript:;"><i class="feather-slash me-2 text-secondary"></i> Block</a>
											</div>
										</li>
									</ul>
								</div>
								<div class="email-menu-blk">
									<ul class="mb-0">
										<li ><a href="javascript:;"><img src="../assets/img/icons/folder-icon-01.svg" class="me-2" alt="img">Personal<span class="comman-flex">50</span></a></li>
										<li><a href="javascript:;"><img src="../assets/img/icons/folder-icon-02.svg" class="me-2" alt="img">Office <span class="comman-flex">120</span></a></li>
										<li><a href="javascript:;"><img src="../assets/img/icons/folder-icon-03.svg" class="me-2" alt="img">Bills <span class="comman-flex">20</span></a></li>
										<li><a href="javascript:;"><img src="../assets/img/icons/folder-icon-04.svg" class="me-2" alt="img">Medical <span class="comman-flex">20</span></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div> */}
  <div className="col-xl-12">
    <div className="card chat-box mb-2">
      <div className=" chat-search-group ">
        <div className="chat-user-group mb-0 d-flex align-items-center">
          <div className="top-check me-3">
            <div className="form-check">
              <input type="checkbox" className="form-check-input" />
            </div>
          </div>
          <div className="top-liv-search top-chat-search top-action-search">
            <form>
              <div className="chat-search mb-0">
                <div className="form-group me-2 mb-0">
                  <input type="text" className="form-control" placeholder="Search here" />
                  <a className="btn"><img src="../assets/img/icons/search-normal.svg"  /></a>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="top-action-right">
          <ul className="nav">
            <li><a href="#" onClick={()=>window.location.reload(true)}><img src="../assets/img/icons/refresh-icon.svg" alt="img" /></a></li>
            {/* <li><a ><img src="../assets/img/icons/tag-icon-04.svg" alt="img"></a></li> */}
            <li><a><img src="../assets/img/icons/inbox.svg" alt="img" /></a></li>
            {/* <li><a><img src="../assets/img/icons/folder-icon-05.svg" alt="img"></a></li> */}
            <li><a><img src="../assets/img/icons/trash.svg" alt="img" /></a></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="email-content">
      <div className="table-responsive">
        <table className="table table-inbox table-hover">
          <thead className="unread clickable-row">
            <tr><th>
              </th>
              <th>S No.</th>
              <th>Email</th>
              <th />
              <th>Date</th>
            </tr></thead>
          <tbody id="tbody">
            {/* <tr class="unread clickable-row">
											<td>
												<div class="top-check ">
													<div class="form-check">
													  <input type="checkbox" class="form-check-input" >
													</div>
												</div>
											</td>
											<td class="name">
												<div class="email-img-blk">
													<div class="send-user">
														<h4>Bernardo James </h4>
													</div>
												</div>
											</td>
											<td></td>
											<td class="mail-date"><img src="../assets/img/icons/clip.svg" class="me-2" alt="img">13:14</td>
										</tr> */}
            {/* <tr class="unread clickable-row" data-href="mail-view.html">
											<td>
												<div class="top-check ">
													<div class="form-check">
													  <input type="checkbox" class="form-check-input" >
													</div>
												</div>
											</td>
											<td><span class="mail-important"><img src="../assets/img/icons/star-empty.svg"  alt="img"></span></td>
											<td class="name">
												<div class="email-img-blk">
													<div class="email-img">
														<img src="../assets/img/profiles/avatar-04.jpg"  alt="img">
													</div>
													<div class="send-user">
														<h4>Galaviz Lalema <span class="email-bills">Bills</span></h4>
														<p>Fwd: quis nostrud exercitation ullamco laboris nisi ut aliquip voluptate velit esse cillum.</p>
													</div>
												</div>
											</td>
											<td></td>
											<td class="mail-date">8:42</td>
										</tr>
										<tr class="clickable-row" data-href="mail-view.html">
											<td>
												<div class="top-check ">
													<div class="form-check">
													  <input type="checkbox" class="form-check-input" >
													</div>
												</div>
											</td>
											<td><span class="mail-important"><img src="../assets/img/icons/star-empty.svg"  alt="img"></span></td>
											<td class="name">
												<div class="email-img-blk">
													<div class="email-img">
														<img src="../assets/img/profiles/avatar-05.jpg"  alt="img">
													</div>
													<div class="send-user">
														<h4>Bernardo James <span class="email-work">Work</span></h4>
														<p>Fwd: tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
													</div>
												</div>
											</td>
											<td></td>
											<td class="mail-date"><img src="../assets/img/icons/clip.svg" class="me-2" alt="img">13:14</td>
										</tr>
										<tr class="unread clickable-row" data-href="mail-view.html">
											<td>
												<div class="top-check ">
													<div class="form-check">
													  <input type="checkbox" class="form-check-input" >
													</div>
												</div>
											</td>
											<td><span class="mail-important"><i class="fas fa-star starred"></i></span></td>
											<td class="name">
												<div class="email-img-blk">
													<div class="email-img">
														<img src="../assets/img/profiles/avatar-06.jpg"  alt="img">
													</div>
													<div class="send-user">
														<h4>Bernardo James <span class="email-office">Office</span></h4>
														<p>Excepteur sint occaecat cupidatat non proident, sunt in culpa</p>
													</div>
												</div>
											</td>
											<td></td>
											<td class="mail-date">18 Sep</td>
										</tr>
										<tr class="clickable-row" data-href="mail-view.html">
											<td>
												<div class="top-check ">
													<div class="form-check">
													  <input type="checkbox" class="form-check-input" >
													</div>
												</div>
											</td>
											<td><span class="mail-important"><i class="fas fa-star starred"></i></span></td>
											<td class="name">
												<div class="email-img-blk">
													<div class="email-img">
														<img src="../assets/img/profiles/avatar-07.jpg"  alt="img">
													</div>
													<div class="send-user">
														<h4>Bernardo James <span class="email-market">Marketting</span></h4>
														<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
													</div>
												</div>
											</td>
											<td></td>
											<td class="mail-date"><img src="../assets/img/icons/clip.svg" class="me-2" alt="img">13:14</td>
										</tr>
										<tr class="clickable-row" data-href="mail-view.html">
											<td>
												<div class="top-check ">
													<div class="form-check">
													  <input type="checkbox" class="form-check-input" >
													</div>
												</div>
											</td>
											<td><span class="mail-important"><img src="../assets/img/icons/star-empty.svg"  alt="img"></span></td>
											<td class="name">
												<div class="email-img-blk">
													<div class="email-img">
														<img src="../assets/img/profiles/avatar-04.jpg"  alt="img">
													</div>
													<div class="send-user">
														<h4>Galaviz Lalema <span class="email-bills">Bills</span></h4>
														<p>Fwd: quis nostrud exercitation ullamco laboris nisi ut aliquip voluptate velit esse cillum.</p>
													</div>
												</div>
											</td>
											<td></td>
											<td class="mail-date">1 Aug</td>
										</tr>
										<tr class="unread clickable-row" data-href="mail-view.html">
											<td>
												<div class="top-check ">
													<div class="form-check">
													  <input type="checkbox" class="form-check-input" >
													</div>
												</div>
											</td>
											<td><span class="mail-important"><img src="../assets/img/icons/star-empty.svg"  alt="img"></span></td>
											<td class="name">
												<div class="email-img-blk">
													<div class="email-img">
														<img src="../assets/img/profiles/avatar-05.jpg"  alt="img">
													</div>
													<div class="send-user">
														<h4>Bernardo James <span class="email-work">Work</span></h4>
														<p>Fwd: tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
													</div>
												</div>
											</td>
											<td></td>
											<td class="mail-date"><img src="../assets/img/icons/clip.svg" class="me-2" alt="img">13:14</td>
										</tr>
										<tr class="clickable-row" data-href="mail-view.html">
											<td>
												<div class="top-check ">
													<div class="form-check">
													  <input type="checkbox" class="form-check-input" >
													</div>
												</div>
											</td>
											<td><span class="mail-important"><i class="fas fa-star starred"></i></span></td>
											<td class="name">
												<div class="email-img-blk">
													<div class="email-img">
														<img src="../assets/img/profiles/avatar-06.jpg"  alt="img">
													</div>
													<div class="send-user">
														<h4>Bernardo James <span class="email-market">Marketting</span></h4>
														<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
													</div>
												</div>
											</td>
											<td></td>
											<td class="mail-date"><img src="../assets/img/icons/clip.svg" class="me-2" alt="img">13:14</td>
										</tr>
										<tr class="unread clickable-row" data-href="mail-view.html">
											<td>
												<div class="top-check">
													<div class="form-check">
													  <input type="checkbox" class="form-check-input" >
													</div>
												</div>
											</td>
											<td><span class="mail-important"><img src="../assets/img/icons/star-empty.svg"  alt="img"></span></td>
											<td class="name">
												<div class="email-img-blk">
													<div class="email-img">
														<img src="../assets/img/profiles/avatar-04.jpg"  alt="img">
													</div>
													<div class="send-user">
														<h4>Bernardo James <span class="email-work">Work</span></h4>
														<p>Fwd: tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
													</div>
												</div>
											</td>
											<td></td>
											<td class="mail-date"><img src="../assets/img/icons/clip.svg" class="me-2" alt="img">13:14</td>
										</tr>
										<tr class="clickable-row" data-href="mail-view.html">
											<td>
												<div class="top-check">
													<div class="form-check">
													  <input type="checkbox" class="form-check-input" >
													</div>
												</div>
											</td>
											<td><span class="mail-important"><i class="fas fa-star starred"></i></span></td>
											<td class="name">
												<div class="email-img-blk">
													<div class="email-img">
														<img src="../assets/img/profiles/avatar-02.jpg"  alt="img">
													</div>
													<div class="send-user">
														<h4>Bernardo James <span class="email-office">Office</span></h4>
														<p>Excepteur sint occaecat cupidatat non proident, sunt in culpa</p>
													</div>
												</div>
											</td>
											<td></td>
											<td class="mail-date">Sep 12</td>
										</tr> */}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default InboxComponent