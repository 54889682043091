import React from 'react'

const GoodMorningBlk = () => {
  return (
    <div>
        <div className="good-morning-blk">
  <div className="row">
    <div className="col-md-6">
      <div className="morning-user">
        <h2>Good Morning, <span>Sudhir Bhayana</span></h2>
        <p>Have a nice day at work</p>
      </div>
    </div>
    <div className="col-md-6 position-blk">
      <div className="morning-img">
        <img src="../assets/img/morning-img-01.png"  />
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default GoodMorningBlk