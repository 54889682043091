import React, { useContext, useState } from 'react'
import Context from '../Context'
import { useNavigate } from 'react-router-dom'
import Firebase from '../Firebase'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const BlogComponent = () => {
  const [DeleteToggle,SetDeleteToggle]=useState(false)
  const[DelKey,SetDelKey]=useState("")
  const navigate=useNavigate()
  const{Fetch}=useContext(Context)
  function Delete(key)
  {
    SetDeleteToggle(!DeleteToggle)
    SetDelKey(key)
  }
  function Del()
  {
      if(DelKey!="")
      {
          Firebase.child(`Blogs/${DelKey}`).remove(err=>{
              if(err)
              {
                  toast.error("Network Error")
              }
              else
              {
                  toast.success("Deleted Successfully")
              }
          })
          SetDeleteToggle(!DeleteToggle)
      }
      else
      {
          toast.error("Some Error Occured in Deletion")
          SetDeleteToggle(!DeleteToggle)
      }
  }
  return (
    <div>
      <ToastContainer position='top-center'/>
<div id="row" className="row">
  {
    Fetch?Object.keys(Fetch).reverse().map(function(key)
  {
    return(
      <div key={key} className="col-sm-6 col-md-6 col-xl-4">
    <div className="blog grid-blog">
      <div className="blog-image">
        <a><img style={{height:"200px"}} className="img-fluid" onClick={()=>(navigate(`/BlogView/${key}`))} src={Fetch[key].Heading_Image?Fetch[key].Heading_Image:"../assets/img/blog/blog-1.jpg"} /></a>
        <div className="blog-views">
          <h5>{Fetch[key].Status?Fetch[key].Status:"Status"}</h5>
        </div>
        {/* <ul className="nav view-blog-list blog-views">
          <li><i className="feather-message-square me-1" />58</li>
          <li><i className="feather-eye me-1" />500</li>
        </ul> */}
      </div>
      <div className="blog-content">
        <div className="blog-grp-blk">
          <div className="blog-img-blk">
            <a><img className="img-fluid" src={Fetch[key].Author_Image?Fetch[key].Author_Image:"../assets/img/profiles/avatar-02.jpg"}  /></a>
            <div className="content-blk-blog ms-2">
              <h4><a>{Fetch[key].Author?Fetch[key].Author:"Author Name"}</a></h4>
              {/* <h5>M.B.B.S, Diabetologist</h5> */}
            </div>
          </div>
          <span><i className="feather-calendar me-1" />{Fetch[key].Date?Fetch[key].Date:"Date of Uploading"}</span>
        </div>
        <h3 onClick={()=>(navigate(`/BlogView/${key}`))} className="blog-title"><a>{Fetch[key].Heading?Fetch[key].Heading:"Heading of Blog"}</a></h3>
          <p>{Fetch[key].Description?Fetch[key].Description.slice(0,200)+" ...":"Description of the Blog"}</p>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...</p> */} 
        <a className="read-more d-flex" style={{cursor:"pointer"}} onClick={()=>(navigate(`/BlogView/${key}`))}>Read more<i className="fa fa-long-arrow-right ms-2" /></a>
        <div className="text-end" style={{position:"relative",bottom:"20px"}}><button className='btn btn-success me-1' onClick={()=>navigate(`/EditBlog/${key}`)}>Edit</button>
        <button className='btn btn-primary me-1' onClick={()=>(navigate(`/BlogView/${key}`))}>View</button>
        <button className='btn btn-danger me-1' onClick={()=>Delete(key)}>Delete</button></div>
      </div>
    </div>
  </div>
    )
  }):""
  }
</div>
<div id="delete_patient" style={DeleteToggle?{display:"block"}:{display:"none"}} className="modal fade delete-modal show" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src="../assets/img/sent.png"  width={50} height={46} />
                            <h3>Are you sure want to delete this Approval?</h3>
                            <div className="m-t-20"> <a href="#" className="btn btn-white" onClick={()=>SetDeleteToggle(false)} data-bs-dismiss="modal">Close</a>
                                <button type="submit" className="btn btn-danger" id="delete" onClick={Del}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={DeleteToggle?{display:"block"}:{display:"none"}} className={DeleteToggle?"modal-backdrop fade show":"modal-backdrop fade"}></div>
    </div>
  )
}
export default BlogComponent