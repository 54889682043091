import React, { useState } from 'react'
import SidebarHeader from '../Components/SidebarHeader';
import PageHeader from '../Components/PageHeader';
import ProfileComponent from '../Components/ProfileComponent';
import Messages from '../Components/Messages';

const MyProfile = () => {
  const [isToggled, setIsToggled] = useState(false);
  const toggleMargin = () => {
    setIsToggled(!isToggled);
  };
  return (
    <div>
      <div id='Main-wrapper' className='main-wrapper' >
        <SidebarHeader toggleMargin={toggleMargin} tooglebtn={isToggled} />
        <div id='page' style={{ marginLeft: isToggled ? '60px' : '' }} className="page-wrapper">
          <div className="content">
            <PageHeader Domain={"My Profile"} SubDomain={""} />
            <ProfileComponent />
            <Messages />
          </div>
        </div>
      </div>
    </div>
  )
}
export default MyProfile