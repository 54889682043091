import React from 'react'

const PageHeader = (props) => {
    return (
        <div>
            {/* Page Header */}
            <div className="page-header">
                <div className="row">
                    <div className="col-sm-12">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item"><a style={{textDecoration:'none'}} >{props.Domain} </a></li>
                            <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                            <li className="breadcrumb-item active">{props.SubDomain}</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* /Page Header */}

        </div>
    )
}

export default PageHeader