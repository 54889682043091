import React from 'react'
import { Link } from 'react-router-dom'

const ProfileComponent = () => {
  return (
    <div>
          <div>
  <div className="row">
    <div className="col-sm-7 col-6">
      <ul className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/Dashboard" style={{textDecoration:"none",color:"black"}}>Dashboard </Link></li>
        <li className="breadcrumb-item"><i className="feather-chevron-right" /></li>
        <li className="breadcrumb-item active">My Profile</li>
      </ul>
    </div>
    <div className="col-sm-5 col-6 text-end m-b-30">
      {/* <a href="edit-profile.html" class="btn btn-primary btn-rounded"><i class="fa fa-plus"></i> Edit Profile</a> */}
      <a onClick={()=>alert('You are not allowed to Edit. Please Contact to Developer')} className="btn btn-primary btn-rounded"><i className="fa fa-plus" /> Edit Profile</a>
    </div>
  </div>
  <div className="card-box profile-header">
    <div className="row">
      <div className="col-md-12">
        <div className="profile-view">
          <div className="profile-img-wrap">
            <div className="profile-img">
              <a href="#"><img className="avatar" src="assets/img/Advisor.jpg"  /></a>
            </div>
          </div>
          <div className="profile-basic">
            <div className="row">
              <div className="col-md-5">
                <div className="profile-info-left">
                  <h3 className="user-name m-t-0 mb-0">Sudhir Bhayana</h3>
                  <small className="text-muted">Advisor</small>
                  <div className="staff-id">Owner</div>
                  {/* <div class="staff-msg"><a href="chat.html" class="btn btn-primary">Send Message</a></div> */}
                </div>
              </div>
              <div className="col-md-7">
                <ul className="personal-info">
                  <li>
                    <span className="title">Phone:</span>
                    <span className="text"><a href="tel:+919255433099">+91 9255433099</a></span>
                  </li>
                  <li>
                    <span className="title">Email:</span>
                    <span className="text"><a href="mailto:A2ZSolutionsRTK@gmail.com"><span className="__cf_email__">A2ZSolutionsRTK@gmail.com</span></a></span>
                  </li>
                  <li>
                    <span className="title">Birthday:</span>
                    <span className="text">26 October,1971</span>
                  </li>
                  <li>
                    <span className="title">Address:</span>
                    <span className="text">Chaman Medicos,Mata Darwaja Chowk,Rohtak</span>
                  </li>
                  <li>
                    <span className="title">Gender:</span>
                    <span className="text">Male</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>                        
      </div>
    </div>
  </div>
 </div>
 {/* <div class="profile-tabs">
					<ul class="nav nav-tabs nav-tabs-bottom">
						<li class="nav-item"><a class="nav-link active" href="#about-cont" data-bs-toggle="tab">About</a></li>
						<li class="nav-item"><a class="nav-link" href="#bottom-tab2" data-bs-toggle="tab">Profile</a></li>
						<li class="nav-item"><a class="nav-link" href="#bottom-tab3" data-bs-toggle="tab">Messages</a></li>
					</ul>

					<div class="tab-content">
						<div class="tab-pane show active" id="about-cont">
          <div class="row">
              <div class="col-md-12">
                  <div class="card-box">
                      <h3 class="card-title">Education Informations</h3>
                      <div class="experience-box">
                          <ul class="experience-list">
                              <li>
                                  <div class="experience-user">
                                      <div class="before-circle"></div>
                                  </div>
                                  <div class="experience-content">
                                      <div class="timeline-content">
                                          <a href="#/" class="name">International College of Medical Science (UG)</a>
                                          <div>MBBS</div>
                                          <span class="time">2001 - 2003</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div class="experience-user">
                                      <div class="before-circle"></div>
                                  </div>
                                  <div class="experience-content">
                                      <div class="timeline-content">
                                          <a href="#/" class="name">International College of Medical Science (PG)</a>
                                          <div>MD - Obstetrics & Gynaecology</div>
                                          <span class="time">1997 - 2001</span>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div class="card-box ">
                      <h3 class="card-title">Experience</h3>
                      <div class="experience-box">
                          <ul class="experience-list">
                              <li>
                                  <div class="experience-user">
                                      <div class="before-circle"></div>
                                  </div>
                                  <div class="experience-content">
                                      <div class="timeline-content">
                                          <a href="#/" class="name">Consultant Gynecologist</a>
                                          <span class="time">Jan 2014 - Present (4 years 8 months)</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div class="experience-user">
                                      <div class="before-circle"></div>
                                  </div>
                                  <div class="experience-content">
                                      <div class="timeline-content">
                                          <a href="#/" class="name">Consultant Gynecologist</a>
                                          <span class="time">Jan 2009 - Present (6 years 1 month)</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div class="experience-user">
                                      <div class="before-circle"></div>
                                  </div>
                                  <div class="experience-content">
                                      <div class="timeline-content">
                                          <a href="#/" class="name">Consultant Gynecologist</a>
                                          <span class="time">Jan 2004 - Present (5 years 2 months)</span>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
						</div>
						<div class="tab-pane" id="bottom-tab2">
							Tab content 2
						</div>
						<div class="tab-pane" id="bottom-tab3">
							Tab content 3
						</div>
					</div>
				</div>
*/}
    </div>
  )
}

export default ProfileComponent