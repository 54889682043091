import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Firebase from '../Firebase';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import JsPDF from 'jspdf';
const ApproveListTable = () => {
	const [DropIndex, setDropIndex] = useState();
    const [dropToggle, setDropToggle] = useState(true);
    const [DeleteToggle,SetDeleteToggle]=useState(false)
    const[Obj,SetObj]=useState("")
    const[Obj1,SetObj1]=useState("")
    const[Delkey,SetDelkey]=useState("")
    const navigate=useNavigate()
    function drop(index) {
        setDropToggle(!dropToggle)
        if (dropToggle) {
            setDropIndex(index)
        }else{
            setDropIndex("")
        }
    }
	useEffect(function()
	{
		Firebase.child("Contacts").orderByChild("Status").equalTo("Approved").on("value",function(snap)
	{
		if(snap.val())
		{
			SetObj(snap.val())
			SetObj1(snap.val())
		}
		else
		{
			SetObj("")
			SetObj1("")
		}
	})
	},[])
	function search(event)
    {
        if(event.target.value=="")
        {
            SetObj(Obj1)
        }
        else
        {
            var obj={}
			var count=0
            Object.keys(Obj1).map(function(key)
            {
                if(Obj1[key].Name.match(event.target.value) || Obj1[key].Phone.match(event.target.value))
                {
					count=count+1
                    obj[key]=Obj1[key]
                }
            })
			if(count==0)
			{
				SetObj("")
			}
			else
			{
				SetObj({...obj})
			}
        }
    }
	function NotApprove(key)
    {
         Firebase.child(`Contacts/${key}/Status`).remove(err=>{
            if(err)
            {
                toast.error("Error Occured")
            }
            else
            {
                toast.success("Disapproved")
            }
        })
    }
	function Delete(key)
    {
        SetDeleteToggle(!DeleteToggle)
        SetDelkey(key)
    }
    function Del()
    {
        if(Delkey!="")
        {
            Firebase.child(`Contacts/${Delkey}`).remove(err=>{
                if(err)
                {
                    toast.error("Network Error")
                }
                else
                {
                    toast.success("Deleted Successfully")
                }
            })
            SetDeleteToggle(!DeleteToggle)
            drop(Delkey)
        }
        else
        {
            toast.error("Some Error Occured in Deletion")
            SetDeleteToggle(!DeleteToggle)
            drop(Delkey)
        }
    }
    function generatePDF()
    {
     let table= document.getElementById('tables')
     const report = new JsPDF('landscape','px',[1140,885]);
     report.html(table).then(() => {
     report.save('Contacts.pdf');})
    }
    return (
        <div>
            <ToastContainer position='top-center'/>
            <div className="row">
                <div className="col-sm-12">
                    <div className="carsd card-table show-entire">
                        <div className="card-body">
                            {/* Table Header */}
                            <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="doctor-table-blk">
                                            <h3>Approved Customers</h3>
                                            <div className="doctor-search-blk">
                                                <div className="top-nav-search table-search-blk">
                                                    <form>
                                                        <input type="text" className="form-control" id="Search" onChange={search} placeholder="Search here by Name or Phone Number" />
                                                        <a className="btn"><img src="../assets/img/icons/search-normal.svg" /></a>
                                                    </form>
                                                </div>
                                                <div className="add-group">
                                                    <a onClick={()=>navigate("/AddCustomer")} className="btn btn-primary add-pluss ms-2"><img src="../assets/img/icons/plus.svg"  /></a>
                                                    <a href="#" onClick={()=>window.location.reload(true)} className="btn btn-primary doctor-refresh ms-2"><img src="../assets/img/icons/re-fresh.svg"  /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto text-end float-end ms-auto download-grp">
                                    <ReactHTMLTableToExcel className="btn btn-primary me-3" table="table-to-xls" filename="Contacts" sheet="Contacts" buttonText="Export Data to Excel"/>
                                    <button className='btn btn-primary' onClick={generatePDF}>Download PDF</button>
                                    </div>
                                </div>
                            </div>
                            {/* /Table Header */}
                            <div id='tables' className="table-responsive">
                                <table id="table-to-xls" className="table table-hover border-0 custom-table comman-table datatabl mb-0">
                                    <thead>
                                        <tr className='text-center'>
                                            <th>Sr. No.</th>
                                            <th>Name</th>
                                            <th>Phone Number</th>
                                            <th>Email</th>
                                            <th>Message</th>
											<th>Date</th>
											<th>Address</th>
                                            <th>Insurance</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Obj?Object.keys(Obj).reverse().map((key, index) =>{
                                                    return(
                                                        <tr className='text-center' key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{Obj[key].Name?Obj[key].Name:""}</td>
                                                        <td>{Obj[key].Phone?Obj[key].Phone:""}</td>
                                                        <td>{Obj[key].Email?Obj[key].Email:""}</td>
                                                        <td>{Obj[key].Message?Obj[key].Message:""}</td>
                                                        <td>{Obj[key].Date?Obj[key].Date:""}</td>
                                                        <td>{Obj[key].Address?Obj[key].Address:""}</td>
                                                        <td>{Obj[key].Insurance?Obj[key].Insurance:""}</td>
                                                        <td className="text-center">
                                                            <div className="dropdown dropdown-action">
                                                                <a onClick={() => drop(key)} style={{cursor:"pointer"}} className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                                <div style={DropIndex === key ? { display: 'block' }: { display: 'none' }} className="dropdown-menu dropdown-menu-end">
                                                                <a className="dropdown-item" onClick={()=>NotApprove(key)} href="#">{' '}Disapprove</a>
                                                                <a className="dropdown-item" onClick={()=>navigate(`/EditCustomer/${key}`)} href="#"><i className="fa-solid fa-pen-to-square m-r-5"></i>{' '}Edit</a>
                                                                <a className="dropdown-item" onClick={()=>Delete(key)} href="#" data-bs-toggle="modal" data-bs-target="#delete_patient"><i className="fa fa-trash-alt m-r-5"></i>Delete</a></div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )
                                            }):<tr className='text-center'>
                                                <td colSpan={9}>No Record Found</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="delete_patient" style={DeleteToggle?{display:"block"}:{display:"none"}} className="modal fade delete-modal show" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src="../assets/img/sent.png"  width={50} height={46} />
                            <h3>Are you sure want to delete this Approval?</h3>
                            <div className="m-t-20"> <a href="#" className="btn btn-white" onClick={()=>SetDeleteToggle(false)} data-bs-dismiss="modal">Close</a>
                                <button type="submit" className="btn btn-danger" id="delete" onClick={Del}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={DeleteToggle?{display:"block"}:{display:"none"}} className={DeleteToggle?"modal-backdrop fade show":"modal-backdrop fade"}></div>       
        </div >
    )
}
export default ApproveListTable